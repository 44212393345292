import React, { useRef, useState } from "react";
import "twin.macro";
import {
  faMapPin,
  faWeightHanging,
  faClock,
  faPenFancy,
  faSearchPlus,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel } from "./ImageCarousel";
import { Dialog } from "@headlessui/react";
import styled from "styled-components";
import tw from "twin.macro";

const ModalWrapper = styled.button`
  ${tw`bg-blue-700 z-20 absolute p-4`};
  right: 3%;
  top: 3%;
`;

export function ForSaleCard(props) {
  let [isOpen, setIsOpen] = useState(false);
  let closeButtonRef = useRef(null);
  const {
    image,
    type,
    weight,
    location,
    buildYear,
    number,
    status,
    notes,
    saleStatus,
  } = props;
  return (
    <>
      <div tw={"h-full w-full mb-8 rounded shadow-xl flex flex-col"}>
        <div tw={"w-full h-64 relative"}>
          {saleStatus !== "For Sale" && saleStatus !== null && (
            <div
              tw={
                "absolute top-0 left-0 right-0 bottom-0 flex justify-center  items-center text-white"
              }
            >
              <p
                tw={
                  "text-3xl uppercase tracking-wide opacity-100 font-bold z-20"
                }
              >
                {saleStatus}
              </p>

              <div
                tw={
                  "opacity-75 bg-blue-700 absolute opacity-75 top-0 z-10 left-0 right-0 bottom-0"
                }
              />
            </div>
          )}
          <div tw={"relative"}>
            <ModalWrapper onClick={() => setIsOpen(true)}>
              <FontAwesomeIcon tw={"text-white"} icon={faSearchPlus} />
            </ModalWrapper>
            {image.length > 1 ? (
              <Carousel $Small images={image} />
            ) : (
              <img
                src={image[0].asset.localFile.childImageSharp.fluid.src}
                alt=""
              />
            )}
          </div>
        </div>
        <div tw={"w-full flex flex-col bg-white relative h-full"}>
          <div tw={"px-8 p-3 bg-blue-800 flex items-center mb-3"}>
            <h2 tw={"text-xl font-bold font-sans text-blue-100"}>{type}</h2>
          </div>
          <div tw={"flex flex-col text-gray-900 z-10 px-8 py-2"}>
            {number && <p tw={"text-lg font-sans mb-1"}>Number - {number}</p>}
            {buildYear && (
              <p tw={"text-lg font-sans mb-1"}>
                <FontAwesomeIcon icon={faClock} tw={"mr-2"} />
                {buildYear}
              </p>
            )}
            {location && (
              <p tw={"text-lg font-sans mb-1"}>
                <FontAwesomeIcon icon={faMapPin} tw={"mr-2"} />
                {location}
              </p>
            )}
            {weight && (
              <p tw={"text-lg font-sans mb-1"}>
                <FontAwesomeIcon icon={faWeightHanging} tw={"mr-2"} />
                {weight}
              </p>
            )}
            {notes && (
              <p tw={"text-lg font-sans mb-1"}>
                <FontAwesomeIcon icon={faPenFancy} tw={"mr-2"} />
                {notes}
              </p>
            )}
          </div>
          {status && (
            <div>
              <p tw={"text-lg font-sans text-gray-800 mb-3 px-8 pt-6"}>
                <span tw={"font-semibold"}>Status</span> - {status}
              </p>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        initialFocus={closeButtonRef}
        tw="fixed z-30 inset-0 overflow-y-auto"
      >
        <div tw="flex items-center justify-center min-h-screen">
          <Dialog.Overlay tw="fixed inset-0 bg-black opacity-75" />

          <div tw="relative bg-white rounded max-w-2xl w-full h-auto mx-auto">
            {image.length > 1 ? (
              <Carousel images={image} />
            ) : (
              <img
                src={image[0].asset.localFile.childImageSharp.fullsize.src}
                alt=""
              />
            )}
            <ModalWrapper
              ref={closeButtonRef}
              onClick={() => setIsOpen(!isOpen)}
            >
              <FontAwesomeIcon icon={faTimes} tw={"text-white"} />
            </ModalWrapper>
          </div>
        </div>
      </Dialog>
    </>
  );
}
